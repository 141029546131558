<template>
  <div class="assetConvergeForm">
    <div class="assetConvergeForm-content">
      <form-panel
        class="form"
        ref="form"
        labelWidth="0"
        :hasHeader="false"
        :submitUrl="submitAssetStandard"
        :form="formList"
        :queryUrl="getAssetDetail"
        :submitBefore="submitBefore"
        @update="update"
      >
        <div class="title">基本信息</div>
        <el-form-item
          prop="name"
          label="选择资产"
          :rules="[
            {
              required: true,
              message: '请输入资产名称',
              trigger: ['change'],
            },
          ]"
        >
          <v-select
            clearable
            :options="assetOptions"
            v-model="formList.name"
            @change="handelChange"
          ></v-select>
        </el-form-item>
        <el-form-item
          prop="type"
          label="资产数据更新周期"
          :rules="[
            {
              required: true,
              message: '请输入资产名称',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <v-radio :radioObj="radioObj" v-model="formList.type"></v-radio>
          <v-input
            v-if="formList.type == 1"
            v-model="formList.cron"
            placeholder="请输入表达式"
          />
        </el-form-item>

        <template v-if="formList.name">
          <div class="title">绑定模型</div>
          <div class="table">
            <div style="margin-bottom: 20px">资产属性：</div>
            <table :key="Math.random()">
              <tr>
                <th
                  width="
              10%"
                >
                  字段名
                </th>
                <th
                  width="
              10%"
                >
                  中文名
                </th>
                <th
                  width="
              70%"
                >
                  关联列
                </th>
                <th
                  width="
              10%"
                >
                  操作
                </th>
              </tr>
              <tr v-for="(item, index) in assetAttributesList" :key="index">
                <td>{{ item.field }}</td>
                <td>{{ item.remark }}</td>
                <td align="left">
                  <draggable
                    :data-index="index"
                    @end="dragEnd"
                    v-model="item.modelFieldList"
                    group="td"
                  >
                    <span v-for="(node, idx) in item.modelFieldList" :key="idx">
                      <v-tag
                        closable
                        :text="`${node.name}-${node.field}`"
                        @close="handelCloseTag($event, item, idx)"
                      ></v-tag>
                    </span>
                  </draggable>
                </td>

                <td>
                  <span @click="handelClickRelation(item, index)"
                    >关联关系</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </template>
        <div class="model" v-if="formList.name">
          <div class="model-table">
            <div
              class="table-content"
              v-for="(item, index) in modelList"
              :key="index"
            >
              <div class="table-title">
                {{ item.name }}
              </div>
              <div class="table">
                <div class="row">
                  <div class="cell">字段</div>
                  <div class="cell">中文名</div>
                </div>
                <draggable
                  chosenClass="chosen"
                  @end="end"
                  :move="moved"
                  v-model="item.fieldConfigs"
                  :group="groupA"
                  handle=".mover"
                  class="table-row"
                >
                  <div
                    class="row"
                    v-for="(node, idx) in item.fieldConfigs"
                    :key="idx"
                  >
                    <div class="cell mover">{{ node.field }}</div>
                    <div class="cell">{{ node.remark }}</div>
                  </div>
                </draggable>
              </div>
              <!-- <table>
                <tr>
                  <td>字段</td>
                  <td>中文名</td>
                </tr>
                <draggable
                  chosenClass="chosen"
                  @end="end"
                  :move="moved"
                  v-model="item.fieldConfigs"
                  :group="groupA"
                  handle=".mover"
                  itemKey="idx"
                >
                  <tr v-for="(node, idx) in item.fieldConfigs" :key="idx">
                    <td class="mover">
                      {{ node.field }}
                    </td>
                    <td>{{ node.remark }}</td>
                  </tr>
                </draggable>
              </table> -->
            </div>
          </div>
          <div
            :class="['add', { active: modelList.length > 0 ? true : false }]"
            @click="handelClickDialog"
          ></div>
        </div>
        <div class="title">数组输出</div>
        <el-form-item
          prop="datastoreId"
          label="选择存储对象"
          :rules="[
            {
              required: true,
              message: '请选择存储对象',
              trigger: ['change'],
            },
          ]"
        >
          <v-select
            clearable
            :options="storeList"
            v-model="formList.datastoreId"
          ></v-select>
        </el-form-item>
        <template #saveBeforeSlot>
          <v-button text="取消" type="plain" @click="$router.back()"></v-button>
        </template>
      </form-panel>
      <v-dialog
        title="指定主键"
        v-model="isShowDialog"
        @confirm="confirmCheck"
        @cancel="cancelCheck"
      >
        <div class="project-check">
          <v-checkboxGroup :options="checkboxOptions" v-model="selectedValues">
          </v-checkboxGroup>
        </div>
      </v-dialog>
      <v-dialog
        title="关联关系"
        v-model="isRelationDialog"
        @confirm="confirmRelation"
        @cancel="cancelCheck"
      >
        <v-select
          label="加功类型:"
          :options="processType"
          v-model="relationData.type"
        >
        </v-select>
        <template v-if="relationData.type == 1">
          <div class="common-style">
            <span>属性列表 :</span>
            <draggable v-model="attrList" :group="groupC">
              <v-tag
                effect="dark"
                v-for="(item, index) in attrList"
                :key="index"
                :text="item.label"
              ></v-tag
            ></draggable>
          </div>
          <div class="common-style">
            <span>计算规则 :</span>
            <draggable v-model="symbolList" :group="groupC">
              <v-tag
                effect="dark"
                v-for="(item, index) in symbolList"
                :key="index"
                :text="item.label"
              ></v-tag
            ></draggable>
          </div>
          <div class="common-style">
            <span>计算数字 :</span>
            <draggable v-model="numberList" :group="groupC" :move="numberMoved">
              <v-tag
                effect="dark"
                v-for="(item, index) in numberList"
                :key="index"
                :text="item.label"
              ></v-tag
            ></draggable>
          </div>
          <div class="common-style">
            <span>计算表达式 :</span>
            <draggable class="expression" v-model="expression" :group="groupD">
              <v-tag
                closable
                effect="dark"
                v-for="(item, index) in expression"
                :key="index"
                :text="item.label"
                @close="handelClose($event, index)"
              ></v-tag>
            </draggable>
          </div>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  getTypeListCode,
  submitAssetStandard,
  getAssetDetail,
  getAssetSourceData,
  getModelList,
  getStoreList,
} from "./api.js";
export default {
  name: "assetConvergeForm",
  components: {
    draggable,
  },
  props: {},
  data() {
    return {
      relationData: {
        type: 0,
        oper: "",
      },
      arr1: [],
      processType: [
        {
          label: "计算加工",
          value: "1",
        },
        {
          label: "直接合并",
          value: "0",
        },
      ],
      attrList: [],
      symbolList: [
        {
          label: "+",
          value: "_add_",
        },
        {
          label: "-",
          value: "_sub_",
        },
        {
          label: "*",
          value: "_multi_",
        },
        {
          label: "/",
          value: "_div_",
        },
      ],
      numberList: [
        {
          label: "0",
          value: 0,
        },
        {
          label: "1",
          value: 1,
        },
        {
          label: "2",
          value: 2,
        },
        {
          label: "3",
          value: 3,
        },
        {
          label: "4",
          value: 4,
        },
        {
          label: "5",
          value: 5,
        },
        {
          label: "6",
          value: 6,
        },
        {
          label: "7",
          value: 7,
        },
        {
          label: "8",
          value: 8,
        },
        {
          label: "9",
          value: 9,
        },
      ],

      groupA: {
        name: "td",
        pull: "clone", //可以拖出
        put: false,
      },
      groupB: {
        name: "td",
        pull: "", //可以拖出
      },
      groupC: {
        name: "expression",
        pull: "clone", //可以拖出
        sort: false,
        put: false,
      },
      groupD: {
        name: "expression",
        pull: "", //可以拖出
        sort: true,
      },
      isShowDialog: false,
      isRelationDialog: false,
      selectedValues: [], //选中的数据
      checkboxOptions: [], //选中的数据
      submitAssetStandard,
      getAssetDetail,
      assetOptions: [], //资产下拉列表
      assetList: [], //资产列表
      assetAttributesList: [], //资产列表
      modelList: [], //模型列表
      requestData: {
        curPage: 1,
        pageSize: 100000,
      },
      radioObj: [
        {
          name: 0,
          value: "实时",
        },
        {
          name: 1,
          value: "固定周期（cron表达式）",
        },
      ],

      formList: {
        id: "",
        name: "", //资产
        type: 0, //更新周期
        cron: "", //cron表达式
        datastoreId: "", //存储对象
      },
      typeOptions: [],
      typeOptionsIds: [],
      cascaderProps: {
        value: "dictKey",
        label: "dictValue",
      },
      filedTypeDictKey: [],
      storeList: [], //存储对象
      dataItems: [], // 存储用户拖拽的数据
      operatorItems: [], // 存储用户拖拽的运算符号
      expression: [], // 存储表达式
      isDragOverTarget: false,
      curData: {},
      attrs: [],
    };
  },
  created() {
    this.getTypeListCode();
    this.getAssetSourceData();
    this.getModelList();
    this.getStoreList();
  },
  watch: {
    // assetAttributesList: {
    //   handler(newValue) {},
    //   deep: true,
    //   immediate: true,
    // },
  },
  mounted() {
    const { id } = this.$route.query;
    if (id != undefined) {
      this.$refs.form.getData({ id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    numberMoved(e) {
      console.log("e----->", e);
    },
    confirmRelation() {
      const result = this.expression.map((item) => {
        return item.value;
      });
      this.relationData.oper = result.join("");
      const node = this.assetAttributesList[this.curData.curIndex];
      const relation = JSON.parse(node.relation);
      relation.oper = this.relationData.oper;
      relation.type = this.relationData.type;
      relation.attrs = this.attrs;
      this.assetAttributesList[this.curData.curIndex].relation =
        JSON.stringify(relation);
      console.log(
        "this.assetAttributesList[this.curData.curIndex]----->",
        this.assetAttributesList[this.curData.curIndex]
      );
      this.isRelationDialog = false;
    },
    //删除计算表达式里面内容
    handelClose(value, index) {
      this.expression.splice(index, 1);
    },
    //表格中tag删除
    handelCloseTag(value, item, index) {
      console.log("1----->", 1);
      item.modelFieldList.splice(index, 1);
      this.$forceUpdate();
    },
    //关联关系
    handelClickRelation(item, index) {
      this.expression = [];
      this.curData.node = item;
      this.curData.curIndex = index;
      const relation = JSON.parse(item.relation);
      this.attrs = item.modelFieldList.map((item) => {
        return `${item.id}-${item.field}`;
      });
      if (Object.keys(relation).length > 0) {
        this.relationData.type = relation.type;
      }
      if (item.modelFieldList.length > 1) {
        this.attrList = item.modelFieldList.map((node) => {
          return {
            value: `${node.id}-${node.field}`,
            label: `${node.name}-${node.field}`,
          };
        });
        this.isRelationDialog = true;
      } else {
        this.attrList = [];
        this.$message.error("未进行关联模型！请添加模型关联");
      }
    },
    //拖拽结束
    dragEnd(e) {
      this.$forceUpdate();
    },
    end(e) {
      this.$forceUpdate();
    },
    moved(evt) {
      console.log("evt----->", evt);
      const node = evt.to;
      console.log("node----->", node);
      const index = node.getAttribute("data-index");
      console.log("evt.draggedContext.element>", evt.draggedContext.element);
      const res = this.assetAttributesList[index];
      // console.log("拖拽元素的指针", evt.draggedContext.index);
      // console.log("拖拽数据本身", evt.draggedContext.element);
      // console.log("拖动后的index", evt.draggedContext.futureIndex);
      // console.log("目标元素的index", evt.relatedContext.index);
      // console.log("element:目标数据本身", evt.relatedContext.element);
      // console.log("list: 拖入的列表", evt.relatedContext.list);
      // console.log("目标组件", evt.relatedContext.component);
      const result = res.modelFieldList.some(
        (item) => item.field == evt.draggedContext.element.field
      );
      if (result) return false;
      return true;
    },
    //选择模型主键
    confirmCheck() {
      this.modelList = this.checkboxOptions.filter((item) =>
        this.selectedValues.includes(item.value)
      );
      console.log("modelList----->", this.modelList);
      this.isShowDialog = false;
    },
    cancelCheck() {
      this.modelList.forEach((item) => {
        this.selectedValues.push(item.id);
      });
    },
    //点击指定主键
    handelClickDialog() {
      this.isShowDialog = true;
    },
    handelChange(value) {
      console.log("value----->", value);
      console.log("this.assetLis----->", this.assetLis);
      const res = this.assetList.find((item) => item.name == value);
      console.log("res----->", res);
      this.assetAttributesList = res.fieldBindConfigs;
      console.log("this.assetAttributesList----->", this.assetAttributesList);
    },
    //获取存储对象
    getStoreList() {
      this.$axios
        .get(getStoreList, { params: this.requestData })
        .then((res) => {
          if (res.code == 200) {
            const { records } = res.data;
            this.storeList = records.map((item) => {
              return { label: item.name, value: item.id };
            });
          }
        });
    },
    //获取模型列表
    getModelList() {
      this.$axios
        .get(getModelList, { params: this.requestData })
        .then((res) => {
          if (res.code == 200) {
            const { records } = res.data;
            // this.modelList = records;
            this.checkboxOptions = records.map((item) => {
              return {
                ...item,
                fieldConfigs: JSON.parse(item.fieldConfigs),
                label: item.name,
                value: item.id,
              };
            });
            this.checkboxOptions.forEach((item) => {
              if (item.fieldConfigs.length > 0) {
                item.fieldConfigs.forEach((node) => {
                  node.id = item.id;
                  node.name = item.name;
                });
              }
            });
          }
        });
    },
    //获取资产标准列表
    getAssetSourceData() {
      this.$axios
        .get(getAssetSourceData, { params: this.requestData })
        .then((res) => {
          if (res.code == 200) {
            const { records } = res.data;
            this.assetList = records;
            console.log("this.assetList----->", this.assetList);
            this.assetOptions = records.map((item) => {
              return { value: item.name, label: item.name };
            });
          }
        });
    },
    // 格式数据
    formatFiledTypeData(data, dictKey = []) {
      data.forEach((v) => {
        if (v.children) {
          this.formatFiledTypeData(v.children, [...dictKey, v.dictKey]);
        } else {
          this.filedTypeDictKey.push(v);
        }

        v.allDictKey = [...dictKey, v.dictKey];
      });
      return data;
    },
    //判定是否为json数据
    isJSON(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            console.log("是JSON");
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.log("error：" + str + "!!!" + e);
          return false;
        }
      }
    },
    //回显
    update(data) {
      Object.keys(this.formList).forEach((key) => {
        this.formList[key] = data[key] || this.formList[key];
      });
      if (this.isJSON(data.cron)) {
        const cron = JSON.parse(data.cron);
        this.formList.type = cron.type;
        this.formList.cron = cron.cron;
      }

      this.assetAttributesList = data.fieldBindConfigs;
      const res = data.fieldBindConfigs.map((item) => item.modelFieldList);
      let newObj = {};
      const arr = res.flat().reduce((preVal, curVal) => {
        newObj[curVal.id] ? "" : (newObj[curVal.id] = preVal.push(curVal));
        return preVal;
      }, []);

      const ids = arr.map((item) => item.id);
      this.selectedValues = ids;
      this.modelList = this.checkboxOptions.filter((item) =>
        ids.includes(item.value)
      );
    },
    //检查是否关联了模型要求
    checkDataValidity(data) {
      return data.every((item) => {
        if (
          item.modelFieldList.length === 0 ||
          item.modelFieldList.length < this.checkboxOptions.length
        ) {
          return false;
        }
        return true;
      });
    },

    submitBefore() {
      const result = this.checkDataValidity(this.assetAttributesList);

      // const fieldBindConfigs = this.assetAttributesList.map((item) => {
      //   return {
      //     field: item.field,
      //     remark: item.remark,
      //     relation: item.relation,
      //   };
      // });
      // const cron = {
      //   cron: this.formList.cron,
      //   type: this.formList.type,
      // };
      // const params = {
      //   name: this.formList.name,
      //   id: this.formList.id,
      //   fieldBindConfigs,
      //   cron: JSON.stringify(cron),
      //   datastoreId: this.formList.datastoreId,
      // };
      // return params;
      if (result) {
        const fieldBindConfigs = this.assetAttributesList.map((item) => {
          return {
            field: item.field,
            remark: item.remark,
            relation: item.relation,
          };
        });
        const cron = {
          cron: this.formList.cron,
          type: this.formList.type,
        };
        const params = {
          name: this.formList.name,
          id: this.formList.id,
          fieldBindConfigs,
          cron: JSON.stringify(cron),
          datastoreId: this.formList.datastoreId,
        };
        return params;
      } else {
        this.$message.warning("请完善信息");
        return false;
      }

      // const res = this.formList.fieldConfigs.map((item) => ({
      //   ...item,
      //   type: item?.type.length > 0 ? item.type[item.type.length - 1] : "",
      // }));
      // const params = {
      //   id: this.formList.id,
      //   name: this.formList.name,
      //   fieldConfigs: res,
      // };
    },
    //获取类型字段
    getTypeListCode() {
      const params = {
        code: "db_type",
      };
      this.$axios.get(getTypeListCode, { params }).then((res) => {
        if (res.code == 200) {
          this.typeOptions = this.formatFiledTypeData(res.data);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.assetConvergeForm {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  .common-style {
    box-sizing: border-box;
    width: 100%;
    margin: 40px 0 40px;
    display: flex;
    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2f3a4f;
      margin-right: 10px;
    }
    > div {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .expression {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      background-color: #fff;
      border: 1px solid #ddd;
      min-height: 50px;
      padding: 10px;
      margin-bottom: 10px;
      > div {
      }
    }
  }
  .assetConvergeForm-content {
    height: calc(100% - 50px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 35px;
    box-sizing: border-box;
    ::v-deep .footer-button {
      display: flex;
      justify-content: center;
    }
    ::v-deep .el-form {
      display: flex;
      flex-direction: column;
      height: 100%;
      .cascader {
        width: 100%;
        .el-cascader {
          width: 100% !important;
        }
      }
    }
    .form-panel-container {
      padding-left: 50px;
      ::v-deep .el-form-item__label {
        width: auto !important;
      }
      .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        color: #000;
      }
      .table {
        width: 100%;
        padding-right: 20px;
        box-sizing: border-box;
        table {
          width: 100%;
          border-collapse: collapse;
          &:not(:last-child) {
            margin-bottom: 40px;
          }
          th,
          td {
            border: 1px solid #999;
            text-align: center;
            padding: 20px 0;
          }
          td {
            &:nth-child(3) {
              > div {
                box-sizing: border-box;
                padding: 0 20px;
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                flex-wrap: wrap;
              }
            }
            &:last-child {
              color: blue;
              span {
                cursor: pointer;
                padding: 0 10px;
              }
            }
          }
        }
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
        .section-circle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 20px;
          .circle-button {
            font-size: 24px;
          }
        }
      }
      .model {
        margin-top: 40px;
        margin-bottom: 20px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        // gap: 20px;
        .model-table {
          box-sizing: border-box;
          gap: 20px;
          display: flex;
          flex-wrap: wrap;
          .table-content {
            width: 300px;
            .table-title {
              margin-bottom: 20px;
            }
            .table {
              display: table;
              width: 100%;
              border-collapse: collapse;
              .table-row {
                display: flex;
                flex-direction: column;
                width: 100%;
              }
              .row {
                display: flex;
                width: 100%;
              }
              .cell {
                flex: 1; /* 确保所有单元格平分空间 */
                border: 1px solid #000;
                padding: 8px;
                box-sizing: border-box; /* 确保padding不会增加元素的总宽度 */
                text-align: center; /* 可选：使文本居中 */
              }
            }
            table {
              width: 100%;
              border-collapse: collapse;
              th,
              td {
                border: 1px solid #999;
                text-align: center;
                padding: 20px 0;
                width: 300px;
              }
              tr {
                width: 100%;
              }
            }
          }
        }
        .chosen {
          background-color: #000 !important;
          color: #fff;
        }

        .add {
          border: 1px dashed;
          width: 100px;
          height: 100px;
          color: #ccc;
          transition: color 0.25s;
          position: relative;
          &.active {
            margin-top: 20px;
            margin-left: 20px;
          }
          &:hover {
            color: blue;
          }
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 80px;
            transform: translate(-50%, -50%);
            border-top: 5px solid;
          }
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 80px;
            border-left: 5px solid;
          }
        }
      }
    }
    .section-search {
      display: flex;
      justify-content: space-between;
      .search-l {
        display: flex;
        .search-input {
          margin-right: 20px;
        }
      }
    }
    .section {
      .title {
        font-weight: 600;
        font-size: 18px;
        color: #000;
        margin-bottom: 20px;
      }
    }
  }
  .project-check {
    min-height: auto;
    max-height: 50vh;
    ::v-deep .el-checkbox-group {
      display: flex;
      flex-direction: column;
      .el-checkbox {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
